export const APP_LINKS = {
  appStore: {
    laboutiqueofficielle: process.env.APP_STORE_LINK_LBO,
    ryses_es: process.env.APP_STORE_LINK_RYSES_ES,
    ryses_it: process.env.APP_STORE_LINK_RYSES_IT,
    ryses_be: process.env.APP_STORE_LINK_RYSES_BE,
    ryses_de: process.env.APP_STORE_LINK_RYSES_DE
  },
  googlePlay: {
    laboutiqueofficielle: process.env.GOOGLE_STORE_LINK_LBO,
    ryses_es: process.env.GOOGLE_STORE_LINK_RYSES_ES,
    ryses_it: process.env.GOOGLE_STORE_LINK_RYSES_IT,
    ryses_be: process.env.GOOGLE_STORE_LINK_RYSES_BE,
    ryses_de: process.env.GOOGLE_STORE_LINK_RYSES_DE
  }
}

export const PAGE_PARAMETER = 'page'
