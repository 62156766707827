<template>
  <div>
    <div class="o-layout js-extra-elements">
      <AppBanner :current-inter-store="currentInterStore" />
      <SaleBannerContext v-if="headerBanner" :banner="headerBanner" />
    </div>
    <div class="o-layout">
      <div>
        <VHeader :is-listing="isListing" />
        <div
          :class="{
            'u-space-header': isSpaceHeaderApplied,
            'u-space-header--breadcrumb': isProduct && !pageLoading,
            'u-space-header--inter': isInternationalDisplay && !pageLoading
          }"
        >
          <!-- https://github.com/nuxt/nuxt.js/issues/5703#issuecomment-845282885 -->
          <div>
            <nuxt />
          </div>
        </div>
        <Reinsurance />
        <NewsletterForm />
        <VFooter>
          <template #left>
            <FooterSitemap />
          </template>
          <template #right>
            <FooterSocialNetwork />
            <FooterApp />
          </template>
        </VFooter>
        <FooterCredits>
          &copy; {{ year }} - {{ $t('components.molecules.footer.credits.copyright') }}
        </FooterCredits>
        <ModalOutlet />
        <ModalLocalStorage />
        <ToastOverlay v-if="displayToaster" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import dayjs from 'dayjs'

import FooterCredits from '@/components/atoms/footer/footer-credits/FooterCredits'
import FooterApp from '@/components/molecules/footer/footer-app/FooterApp'
import FooterSocialNetwork from '@/components/molecules/footer/footer-social-network/FooterSocialNetwork'
import FooterSitemap from '@/components/molecules/footer/footer-sitemap/FooterSitemap'
import NewsletterForm from '@/components/molecules/footer/newsletter-form/NewsletterForm'
import Reinsurance from '@/components/molecules/footer/reinsurance/Reinsurance'
import ModalOutlet from '@/components/molecules/modal/modal-outlet/ModalOutlet'
import productToastMixin from '@/mixins/productToastMixin'
import SaleBannerContext from '@/components/molecules/header/sale-banner/SaleBannerContext'
import VFooter from '@/components/organisms/layout/footer/VFooter'
import VHeader from '@/components/organisms/layout/header/VHeader'
import { sendSentryMessage } from '@/helpers/errorHelper'
import ModalLocalStorage from '@/components/organisms/home/modal-localstorage/ModalLocalStorage.vue'
export default {
  name: 'Default',
  components: {
    AppBanner: () => import('@/components/molecules/header/app-banner/AppBanner'),
    FooterApp,
    FooterCredits,
    FooterSitemap,
    FooterSocialNetwork,
    ModalOutlet,
    ModalLocalStorage,
    NewsletterForm,
    Reinsurance,
    SaleBannerContext,
    ToastOverlay: () => import('@/components/molecules/toast/ToastOverlay'),
    VFooter,
    VHeader
  },
  mixins: [productToastMixin],
  middleware: ['marketingCloudConversion'],
  data () {
    return {
      year: dayjs().format('YYYY')
    }
  },
  computed: {
    isListing () {
      return this.pageName != null && this.pageName.startsWith('all')
    },
    isProduct () {
      return this.pageName != null && (this.pageName.startsWith('p-all') || this.pageName.startsWith('marques___fr'))
    },
    isSpaceHeaderApplied () {
      return !this.pageLoading || this.pageName === 'error'
    },
    ...mapState({
      pageName: state => state.pagename,
      popinOutlet: state => state.popinOutlet,
      localStorageWarnAccepted: state => state.localStorageWarnAccepted,
      pageLoading: state => state.ui.pageLoading,
      headerBanner: state => state.headerBanner,
      currentInterStore: state => state.international && state.international.currentInterStore
    }),
    ...mapGetters({
      isInternationalDisplay: 'international/isInternationalDisplay'
    })
  },

  watch: {
    popinOutlet (val) {
      if (val) {
        this.$modal.show('outlet')
      }
    }
  },

  mounted () {
    if (!this.$localStorage && !this.localStorageWarnAccepted) {
      this.$modal.show('localStorage')
    }

    if (!this.currentInterStore) {
      sendSentryMessage.bind(this, 'Store is undefined or null', 'fatal', {
        action: 'Mounted Layout',
        currentInterStore: this.currentInterStore
      })()

      this.$error({ statusCode: 500 })
    }

    if (this.popinOutlet) {
      this.$modal.show('outlet')
    }
  }
}
</script>
