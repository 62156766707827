/* @format */

/**
 * A Severity Level (defined by Sentry)
 * @typedef {('fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug')} SeverityLevel
 */

/**
 * Send a message to Sentry
 * @param {string} message
 * @param {SeverityLevel} severityLevel 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'
 * @param extra
 * @returns {boolean}
 */
export function sendSentryMessage(
  message = '',
  severityLevel = 'error',
  extra = {},
) {
  if (!this.$sentry) {
    return false
  }

  const user = this?.$auth?.$state?.user

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    sentryLogger(severityLevel, message, extra)
  }

  this.$sentry.captureMessage(message, {
    level: severityLevel,
    tags: {
      userId: user?.id || null,
      userEmail: user?.email || null,
    },
    extra,
  })
}

/* eslint-disable no-console */
export function objectLogger(object) {
  if (process.server) {
    console.dir(object, {
      colors: true,
      depth: 4,
    })
  }

  if (process.client) {
    console.log(object)
  }
}

function sentryLogger(severityLevel, message, extra) {
  const addExtraContext = () => {
    if (!Object.keys(extra).length) {
      return
    }
    objectLogger(extra)
  }

  if (severityLevel === 'warning') {
    console.warn(message)
    addExtraContext()
    return
  }

  if (['error', 'fatal'].includes(severityLevel)) {
    console.error(message)
    addExtraContext()
    return
  }

  if (['info', 'debug', 'log'].includes(severityLevel)) {
    console.log(message)
    addExtraContext()
  }
}
/* eslint-enable no-console */
