import { sendSentryMessage } from './../helpers/errorHelper'
export const getTranslations = async (context, locale, yml) => {
  context.$axios.setBaseURL(context.$config.apiUrl)

  // This request is executed before axios plugin initialization, so we need to attach a specific interceptor
  const translationRequestInterceptor = context.$axios.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.code && error.code === 'ECONNABORTED') {
      return Promise.reject(new Error('Network Error: Request aborted'))
    }

    return Promise.reject(error)
  })

  try {
    return await context.$axios.$get('/translations')
  } catch (e) {
    sendSentryMessage.bind(context, 'Unable to load translations', 'fatal', {
      action: `Load ${locale}`,
      details: e.request,
      call: 'getTranslation',
      exception: e
    })()
    context.$sentry.captureException(e)
    return await Promise.resolve(yml)
  } finally {
    context.$axios.interceptors.request.eject(translationRequestInterceptor);
  }
}
