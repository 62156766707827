export const URL_PROTOCOL = process.env.URL_PROTOCOL

export const MAINTENANCE = parseInt(process.env.MAINTENANCE)

export const IMAGE_PREFIX = 'https://cdn.laboutiqueofficielle.com'

export const PAGETYPE = {
  NOVELTIES: 'NOVELTIES',
  TOPSALES: 'TOP_SALES',
  PROMO: 'PROMOTIONS'
}

/*
    XXS: Panier D + Panier M + Compte D + Compte M ( 120 )
    XS: Home M + Home D + Listing M ( 210 )
    S: Listing D ( 360 )
    M: Produit D + Produit M ( 450 )
    L: unused ( 720 )
    XL: unused ( 900 )
    XXL: Zoom D, Zoom M ( 1100 )
*/
export const SIZE = {
  XXS: process.env.SIZE_XXS,
  XS: process.env.SIZE_XS,
  S: process.env.SIZE_S,
  M: process.env.SIZE_M,
  L: process.env.SIZE_L,
  XL: process.env.SIZE_XL,
  XXL: process.env.SIZE_XXL
}

export const WATERMARK = {
  POSITION: process.env.WATERMARK_POSITION,
  MARGIN_X: process.env.WATERMARK_MARGIN_X,
  MARGIN_Y: process.env.WATERMARK_MARGIN_Y,
  MARGIN_X_MOBILE: process.env.WATERMARK_MARGIN_X_MOBILE,
  MARGIN_Y_MOBILE: process.env.WATERMARK_MARGIN_Y_MOBILE,
  OPACITY: process.env.WATERMARK_OPACITY,
  SIZE: process.env.WATERMARK_SIZE,
  SIZE_MOBILE: process.env.WATERMARK_SIZE_MOBILE
}

export const FACEBOOK_CLIENT_ID = process.env.FACEBOOK_CLIENT_ID

export const APPLE_CLIENT_ID = process.env.APPLE_CLIENT_ID
export const APPLE_SCOPES = 'name email'
export const APPLE_REDIRECT_URI = process.env.APPLE_REDIRECT_URI

export const API_URL = process.env.BACK_API_URL

export const NB_RATING_PER_PAGE = 10

export const BREAKPOINT = 1024

export const APP_BANNER_NB_OF_HIDING_DAYS = process.env.APP_BANNER_NB_OF_HIDING_DAYS

export const ORDER_STATUS_SENT = 4

// TODO: Virer search des URLS statiques qaund le back sera ok
export const STATIC_URL = [
  '/checkout',
  '/account',
  '/unsubscribe',
  '/search',
  '/login',
  '/wishlist',
  '/maintenance',
  '/contact',
  '/autoconnect',
]

export const PROVIDER_SCRIPT = {
  add: {
    hipay: 'https://libs.hipay.com/js/sdkjs.js'
  },
  remove: {
    hipay: 'https://libs.hipay.com'
  }
}

export const PAYMENT_METHOD_ID = {
  VOUCHER_AND_CREDIT: 10,
  CB: 12,
  CHEQUE: 2,
  BANK_TRANSFER: 11,
  PAYPAL: 7
}

export const AUTHORIZED_COLOR_DEPTH = [1, 4, 8, 15, 16, 24, 32, 48]

export const MAX_API_CALL = 7

export const TOP_SALES_URL_TRANSLATIONS = {
  fr: '/top-ventes',
  es: '/top-ventas',
  it: '/vendite-top',
  nl: '/bestsellers',
  de: '/bestsellers',
}

export const BTN_LINK = {
  NOVELTY: {
    fr: '/nouveautes',
    es: '/novedades',
    it: '/notizie',
    nl: '/nieuw',
    de: '/neu',
  },
  TOP_SALES: TOP_SALES_URL_TRANSLATIONS
}

export const GLOSSARY_TEMPLATE_SLUG = {
  35: 'artiste_sport',
  16: 'artiste_culte_geek',
  21: 'artiste_hip_hop_rap'
}

// FIXME: Actually using locale to get the currency. Should use the country code
export const CURRENCY = {
  fr: 'EUR',
  es: 'EUR',
  it: 'EUR',
  nl: 'EUR',
  de: 'EUR',
}

export const DATE_FORMAT = {
  DELIVERY_DATE: {
    fr: 'dddd D MMMM',
    it: 'dddd D MMMM',
    es: 'dddd D [de] MMMM',
    nl: 'dddd D MMMM',
    de: 'dddd D MMMM',
  }
}

export const PAYPAL_CLIENT_ID = process.env.PAYPAL_CLIENT_ID

export const NOSTO_ACCOUNT_ID = process.env.NOSTO_ACCOUNT_ID

export const TYPE_OF_PAGE = {
  artists: 'LISTING',
  marques: 'LISTING',
  all: 'LISTING',
  'p-all': 'PRODUCT',
  index: 'HOME',
  'search-query': 'LISTING'
}

export const EXCLUDED_SKELETON_NAME = ['account', 'account-history', 'account-subscriptions', 'contact', 't-name', 'tp-name', 'wishlist']

export const EXTERNAL_SCRIPT = {
  ABTASTY: '//try.abtasty.com/72ef1ebd01d5ca0dbb9be2ec26a20e1f.js',
  ABTASTY_RYSES: '//try.abtasty.com/4bc49fa3bc715639acc0c1747d0e65ae.js',
  APPEL_CONNECT: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  FACEBOOK_CONNECT: 'https://connect.facebook.net/fr_FR/sdk.js',
  GOOGLE_CONNECT: 'https://accounts.google.com/gsi/client',
  PAYPAL_INFO: `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&components=messages`
}
export const DOMAINS = {
  LBO: 'laboutiqueofficielle',
  RYSES: 'ryses'
}

export const SITEMAPS = {
  LBO: process.env.SITEMAP_LBO,
  ES: process.env.SITEMAP_RYSES_ES,
  IT: process.env.SITEMAP_RYSES_IT,
  BE: process.env.SITEMAP_RYSES_BE,
  DE: process.env.SITEMAP_RYSES_DE
}

export const SANDBOX_PATTERN = /www-[a-z]+.sbx-lbo.dev/

export const CHRISTMAS_DATE_BANNER = {
  laboutiqueofficielle: {
    content1: process.env.CHRISTMAS_FR_DATE_CONTENT_1,
    content2: process.env.CHRISTMAS_FR_DATE_CONTENT_2,
    content3: process.env.CHRISTMAS_FR_DATE_CONTENT_3,
    content4: process.env.CHRISTMAS_FR_DATE_CONTENT_4,
    endDate: process.env.CHRISTMAS_FR_END_DATE
  },
  ryses_es: {
    content1: process.env.CHRISTMAS_ES_DATE_CONTENT_1,
    content2: process.env.CHRISTMAS_ES_DATE_CONTENT_2,
    content3: process.env.CHRISTMAS_ES_DATE_CONTENT_3,
    content4: process.env.CHRISTMAS_ES_DATE_CONTENT_4,
    endDate: process.env.CHRISTMAS_ES_END_DATE
  },
  ryses_it: {
    content1: process.env.CHRISTMAS_IT_DATE_CONTENT_1,
    content2: process.env.CHRISTMAS_IT_DATE_CONTENT_2,
    content3: process.env.CHRISTMAS_IT_DATE_CONTENT_3,
    content4: process.env.CHRISTMAS_IT_DATE_CONTENT_4,
    endDate: process.env.CHRISTMAS_IT_END_DATE
  },
  ryses_be: {
    content1: process.env.CHRISTMAS_BE_DATE_CONTENT_1,
    content2: process.env.CHRISTMAS_BE_DATE_CONTENT_2,
    content3: process.env.CHRISTMAS_BE_DATE_CONTENT_3,
    content4: process.env.CHRISTMAS_BE_DATE_CONTENT_4,
    endDate: process.env.CHRISTMAS_BE_END_DATE
  },
  ryses_de: {
    content1: process.env.CHRISTMAS_DE_DATE_CONTENT_1,
    content2: process.env.CHRISTMAS_DE_DATE_CONTENT_2,
    content3: process.env.CHRISTMAS_DE_DATE_CONTENT_3,
    content4: process.env.CHRISTMAS_DE_DATE_CONTENT_4,
    endDate: process.env.CHRISTMAS_DE_END_DATE
  }
}

export const NODE_INSTANCE_NUMBER = process.env.NODE_APP_INSTANCE
export const STORE = process.env.STORE
export const LOCALE = process.env.LOCALE
export const REDIS_DB = process.env.REDIS_DB

export const TOLSTOY_STORY_ID = process.env.TOLSTOY_STORY_ID
